.ant-drawer-wrapper-body {
    overflow: hidden !important;
}
.ant-drawer-body{
    padding: 0 !important;
}

.demes-help-container {
    display: flex; /* or inline-flex */
    height: 94vh;
}

.demes-help-tree {
    flex: 0 0 300px;
    padding: 10px;
    border-right: solid 2px #EEEEEE;
    height: 94vh;

    overflow: auto;
}

.demes-help-content {
    padding: 10px;
    height: 100%;
    overflow: auto;
    width: 100%;
    /* display: flex; */
}

/* .demes-help-md-wrap {
    width: 400px;
} */

.demes-help-current-video{
    font-weight: bold;
}

.demes-help-video-link {
    cursor: pointer; 
}

.demes-help-player-wrap{
    background-color: #000;
    vertical-align: middle;
    height: 400px;
}

.demes-help-player-wrap img{
    max-width: 100%;
    max-height: 100%;

    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.demes-help-player-wrap audio{
    width: 50%; 
    height: 225px;

    /* position: relative; */
    /* top: 50%; */
    /* transform: translateY(-50%);   
    transform: translateX(+50%);     */
    transform: translateX(+50%);
}

.demes-help-media-list{
    list-style-type: none;
}

.demes-help-media-list li{
    margin: 0 0 0 -30px;
}

.demes-help-drawer-wrap .ant-drawer-title i {
    padding: 0 5px 0 0;
}