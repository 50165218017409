.demes-app-component-wrap {
    width: 100%;
    height: 100%;
    /* background-color: #FFF; */
    text-align: center;
    overflow: auto;
    max-height: 100%;
}
/*
.demes-app-component-wrap pre {
    height: 300px;
}
*/

.demes-app-component-wrap pre {
    text-align: left;
    padding: 10px;
    background-color: bisque;
}

.listing-label {
    text-align: right;
    margin: -15px 5px 0 0;
}