.responsive-table-colfilter-wrap {
    padding: 10px;
}

.responsive-table-filter-button-wrap {
    text-align: right;
    margin: 10px 0 0 0;
}

.responsive-table-filter-button-wrap button {
    margin: 0 0 0 5px;
}

.customfilter-wrap {
    display: flex;
    flex-direction: row;
    border: solid 0px #FF0000;
}

.customfilter-value-wrap {
    margin: 0 0 0 5px !important;
}