/* ********************************************************************** */
/* .global */
/* ********************************************************************** */
a {
    color: rgba(42, 63, 84, 1);
}

/* ********************************************************************** */
/* .demes-left-sider */
/* ********************************************************************** */
.demes-left-sider .ant-menu {
    color: rgba(42, 63, 84, 1) !important;
    color: #FFF !important;
    color: #000 !important;
}

.demes-left-sider .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: 1px solid rgba(243, 172, 0, 1) !important;
}

.demes-left-sider .ant-menu-submenu .ant-menu-submenu-inline{
    color:#FFF  !important;
    color:#000  !important;
}

.demes-left-sider .ant-menu-submenu-selected {
    color: #FFF !important;
    color: #000 !important;
}

.demes-left-sider .ant-menu-item:hover, 
.demes-left-sider .ant-menu-item-active, 
.demes-left-sider .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, 
.demes-left-sider .ant-menu-submenu-active, 
.demes-left-sider .ant-menu-submenu-title:hover {
    color: #FFF !important;
    color: #000 !important;
}

.demes-left-sider .ant-menu-submenu-title i {
    padding: 0 5px 0 0;
}

.demes-left-sider .ant-menu-item-selected {
    background: rgba(243, 172, 0, .1) !important;
}

.demes-left-sider .ant-menu-item-selected > a {
    color: #FFF !important;
    color: #000 !important;
}

.demes-left-sider .ant-menu-item {
    color: #FFF !important;
    color: #000 !important;
}

.demes-left-sider .ant-menu-item > a {
    color: #FFF !important;
    color: #000 !important;
}

.demes-left-sider .ant-menu-item-disabled > a {
    color: rgba(255,255,255,.5) !important;
}

.demes-left-sider .ant-menu-item .ant-menu-item-active {
    color: rgba(248, 175, 0, 1) !important;
    color: #000 !important;
}

.demes-left-sider .ant-menu-item .ant-menu-item-active:hover {
    color: rgba(248, 175, 0, 1) !important;
    color: #000 !important;
}

.demes-left-sider .ant-menu-item > a:hover {
    color: rgba(248, 175, 0, 1) !important;
    color: rgba(0, 0, 0, .5) !important;
}

.demes-left-sider .ant-menu-vertical .ant-menu-item::after, 
.demes-left-sider .ant-menu-vertical-left .ant-menu-item::after,
.demes-left-sider .ant-menu-vertical-right .ant-menu-item::after,
.demes-left-sider .ant-menu-inline .ant-menu-item::after {
    border-right: 10px solid rgba(248, 175, 0, 1);
    border-right: 10px solid !important;
}

.demes-left-sider .ant-menu-inline .ant-menu-item, 
.demes-left-sider .ant-menu-inline .ant-menu-submenu-title {
    width: calc(100% + 1px);
}

.demes-left-sider .ant-layout-sider-trigger {
    display: none;
}

/* ********************************************************************** */
/* demes-top-menu */
/* ********************************************************************** */
.demes-top-header {
    padding: 20px 0 0 0 !important;
    text-align: right;
    border-bottom: solid 1px rgba(25, 36, 46, .2) !important;
    height: 47px !important;
    height: 67px !important;
    height: 87px !important;
    background: #FFF !important;
}

.demes-top-menu {
    background: rgba(237, 237, 237, 1) !important;
    color: rgba(42, 63, 84, 1) !important;
}

.demes-top-menu .ant-menu-item:hover, 
.demes-top-menu .ant-menu-item-active, 
.demes-top-menu .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, 
.demes-top-menu .ant-menu-submenu-active,
.demes-top-menu .ant-menu-submenu-title:hover {
    color: rgba(42, 63, 84, .8) !important;
}

.demes-top-menu .ant-menu-submenu .ant-menu-submenu-horizontal:hover {
    color: rgba(42, 63, 84, .8) !important;
}

.topmenu-layout-header-right .ant-menu {
    background: transparent !important;
}

.ant-menu-item i {
    padding: 0 5px 0 0;
}

.submenu-title-wrapper i {
    padding: 0 5px 0 0;
}

/* ******************************************************************************************* */

.demes-left-sider .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: 0px solid rgba(42, 63, 84, 1) !important;
}

/* ******************************************************************************************* */

.ant-btn-primary {
    color: rgba(42, 63, 84, 1) !important;
    background-color: rgba(248, 175, 0, 1) !important;
    border-color: rgba(248, 175, 0, 1) !important;
}

.ant-btn-primary:disabled {
    color: rgba(42, 63, 84, 1) !important;
    background-color: rgba(248, 175, 0, .2) !important;
    border-color: rgba(248, 175, 0, .2) !important;
}

.ant-input:hover {
    border-color: rgba(248, 175, 0, 1) !important;
}

.ant-input:focus, .ant-input-focused {
    border-color: rgba(248, 175, 0, 1) !important;
}

::selection {
    color: #fff;
    background: rgba(248, 175, 0, 1) !important;
}


html {
    --antd-wave-shadow-color: rgba(248, 175, 0, 1) !important;
    --scroll-bar: 0;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-sector{
    border-color: #FF0000 !important;
}

.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
    background: linear-gradient(to right, rgba(248, 175, 0, 1), rgba(248, 175, 0, 1)) !important;
    background: -webkit-gradient(linear, left top, right top, from(rgba(248, 175, 0, 1)), to(rgba(248, 175, 0, 1))) !important;
}

.ant-slider-handle {
    border: solid 2px rgba(248, 175, 0, 1) !important;
}

.ant-btn:hover, .ant-btn:focus {
    color: rgba(248, 175, 0, 1) !important;
    background-color: #fff;
    border-color: rgba(248, 175, 0, 1) !important;
}

a:hover{
    color: rgba(248, 175, 0, 1) !important;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
    color: rgba(255, 255, 255, 1) !important;
}

.ant-pagination-item-active {
    border-color: rgba(248, 175, 0, 1) !important;
}


.ant-slider-track {
    background-color: rgba(248, 175, 0, .4) !important;
}

.ant-tooltip-inner {
    background-color: rgba(42, 63, 84, 1) !important;
}

.ant-tooltip-arrow::before {
    background-color: rgba(42, 63, 84, 1) !important;
}

.ant-input-affix-wrapper:hover{
    border-color: rgba(248, 175, 0, 1) !important;
}

.ant-tabs-nav .ant-tabs-tab:hover {
    color: rgba(248, 175, 0, 1) !important;
    color: rgba(0, 0, 0, .5) !important;
}

.ant-spin {
    color: rgba(248, 175, 0, 1) !important;
}
.ant-spin .ant-spin-dot-item {
    background-color: rgba(248, 175, 0, 1) !important;
}

.ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
    color: rgba(248, 175, 0, 1) !important;
}

.ant-table-filter-trigger.active {
    color: rgba(248, 175, 0, 1) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgba(248, 175, 0, 1) !important;
    border-color: rgba(248, 175, 0, 1) !important;
}

.ant-table-row-expand-icon:focus, .ant-table-row-expand-icon:hover {
    color: rgba(248, 175, 0, 1) !important;
}

.ant-input-number:hover {
    border-color: rgba(248, 175, 0, 1) !important;
}

.ant-picker:hover, .ant-picker-focused {
    border-color: rgba(248, 175, 0, 1) !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: rgba(248, 175, 0, 1) !important;
}

.ant-tabs-nav .ant-tabs-tab-active {
    color: rgba(248, 175, 0, 1) !important;
    font-weight: 500;
    outline: none;
}

.ant-tabs-ink-bar {
    background-color: rgba(248, 175, 0, 1) !important;
}

.demes-menu-outer-wrap .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    border-bottom: solid 1px  rgba(248, 175, 0, 1) !important;
    background-color: rgba(248, 175, 0, 1) !important;
    color: rgba(0, 0, 0, 1) !important;
}

/* ****************************************************************************** */

.brand-logo {
    overflow: hidden;
    padding: 20px 0 20px 0 !important;
    margin: 0 !important;
    height: 66px;
    height: 86px;   
}

.brand-logo-collapsed{
    border: solid 0px #FF0000;
    height: 86px !important;
    padding: 30px 0 0 0;
    overflow: hidden;
    border-right: solid 1px rgba(0, 0, 0, 1);
}

.brand-logo img{
    margin: 0 !important;
}

.topmenu-layout-header-center h1 {
    color:  rgba(248, 175, 0, 1) !important;
    font-size: 143%;
    padding: 29px 0 0 0;
    font-family: 'Source Sans Pro', sans-serif !important;
    font-weight: bold;
}

.demes-menu-footer {
    color: #000 !important;
}


.demes-menu-outer-wrap .demes-menu-footer a {
    color: #000 !important;
}


.demes-left-sider .ant-menu-item {
    background-color: rgba(255,255,255, .2);
}

.demes-left-sider .ant-menu-submenu {
    background-color: rgba(255,255,255, .2);
    margin-bottom: 8px !important;
    padding-bottom: 0px !important;
}

.demes-left-sider .ant-menu-item-selected {
    background-color: rgba(255,255,255, .5) !important;
}

body {
    color: rgba(0, 0, 0, 1) !important;
}

.ant-menu {
    color: rgba(0, 0, 0, 1) !important;
}

th.ant-table-cell {
    background-color: rgba(249, 191, 51, .4) !important;
    background-color: rgba(0, 0, 0, .1) !important;
}

.anticon .anticon-filter {
    color: rgba(0, 0, 0, 1) !important;
}

.ant-table-filter-trigger {
    color: rgba(0, 0, 0, 1) !important;
}

.ant-table-column-sorter .ant-table-column-sorter {
    color: rgba(0, 0, 0, 1) !important;
}


.demes-menu-outer-wrap .ant-tabs-nav-scroll{
    background-color: rgba(255, 255, 255, 1) !important;
    border-right: solid 1px #000;
    
}

.demes-menu-outer-wrap .ant-tabs-nav .ant-tabs-tab{
    border-bottom: rgba(248, 175, 0, 1) !important;
}