.demes-top-header .ant-menu .ant-menu-light .ant-menu-root .ant-menu-horizontal {
    border: solid 20px #FF0000 !important;
}

.topmenu-layout-header {
    line-height: 0 !important;
    display: flex;
}

.topmenu-layout-header-left{
    width: 25%;
    margin: 0px;
    padding: 7px 0 0 10px;
    text-align: left;
}

.topmenu-layout-header-center{
    width: 50%;
    text-align: center !important;
    padding: 7px 0 0 10px;
    margin-right: 5px;
}

.topmenu-layout-header-right{
    padding: 7px 10px 0 0;
    width: 25%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    height: 64px;
}

.topmenu-layout-header-right-loggedin{
    padding: 7px 10px 0 0;
    width: 25%;
    height: 64px;
}

.topmenu-layout-header-right-loggedin .username {
    /* float: right; */
    margin: 0 5px 0 0;
    border: solid 0px #FF0000;
    display: inline-block;
}


.topmenu-layout-header-right button {
    /* float: right; */
    margin: 0 0 0 5px;
}
    
.topmenu-layout-header-right ul {
    float: right;
}


.demes-topmenu-icon {
    border: solid 0px #FF0000;
    display: inline-block;
    width: 25px;
    text-align: center;
}