.demes-navtopright-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.demes-navtopright-toolbar {
    border: solid 0px #0000FF;
    text-align: right;
    border-bottom: solid 1px rgb(168, 168, 168);
}

.demes-navtopright-body {
    border: solid 0px #00FF00;
    background: #FFF;
    overflow: auto;
    height: calc(100vh - 48px - 48px);
    display: flex;
}

.demes-navtopright-content {
    width: 75%;
    border-right: solid 1px rgb(168, 168, 168);
    overflow: auto;
}

.demes-navtopright-rightnav {
    width: 25%;
    overflow: auto;
}