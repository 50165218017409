.demes-navtop-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.demes-navtop-toolbar {
    border: solid 0px #0000FF;
    text-align: right;
    border-bottom: solid 1px rgb(168, 168, 168);
}

.demes-navtop-body {
    border: solid 0px #00FF00;
    background: #FFF;
    overflow: auto;

    height: calc(100vh - 48px - 48px);
}