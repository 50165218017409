.resp-table {
    flex: content;
    overflow: auto;
    border: solid 10px #FF0000;
    height: 100%;
}

.resp-table-wrap {
    display: flex;
    height: 100%;
    background-color: rgba(255,255,255,1);
}

.resp-table-wrap .toptoolbar {
    height: 50px;
    flex-shrink: 0;
}

.resp-table-wrap .content {
    overflow: auto;
    flex: auto;
}

.resp-table-wrap .paging {
    height: 50px;
    flex-shrink: 0 !important;
}

/* ************************** */

.toptoolbar {
    text-align: right;
    background-color: rgb(200, 200, 200) !important;
    border-bottom: solid 1px rgba(0, 20, 40, 1);
    color: rgba(255, 255, 255, .9);
    padding: 5px;
    z-index: 100;
}

.toptoolbar button {
    margin: 0 0 0 10px;
    width: 50px;
    color: rgba(255, 255, 255, .9);
}

.resp-table-wrap .menu {
    position: absolute;
    background-color:rgba(255, 255, 255, 1) !important;
    border-bottom: solid 1px rgba(0, 20, 40, 1);
    padding: 20px;
    overflow: auto;
    top: 50px;
    max-height: calc(100vh - 100px);
    width: 100%;
    z-index: 100;
}

.paging {
    text-align: center;
    background-color: rgba(76, 90, 104, 0);
    border-top: solid 1px rgba(0, 20, 40, 1);
    padding: 10px;
}

.resp-table-wrap-mobile {
    display: flex;
    flex-direction: column;
}

.resp-table-toolbar-wrap {
    text-align: right;
    background-color: rgba(76, 90, 104, 1);
    border-bottom: solid 1px rgba(0, 20, 40, 1);
    color: rgba(255, 255, 255, .9);
    padding: 5px;
    z-index: 100;
    position: relative;
}

.resp-table-toolbar-wrap button {
    margin: 0 0 0 10px;
    width: 50px;
    color: rgba(255, 255, 255, .9);
}
/* ********************* */


.resp-table-menu-button-wrap {
    text-align: center;
}

.resp-table-menu-button-wrap button {
    margin: 5px;
}

/* ********************* */
.resp-table-menu-wrap {
    position: relative;
    padding: 20px;
    border-bottom: solid 1px rgba(0, 20, 40, 1);
    background-color: rgba(0, 20, 40, .8);
    z-index: 10;
}

/* ********************* */

.resp-table-wrap .ant-collapse-header{
    background-color: rgba(76, 90, 104, .01);
    border-bottom: solid 1px rgba(76, 90, 104, .1);;
}

.resp-table-wrap .ant-collapse-item-active .ant-collapse-header {
    background-color: rgba(248, 175, 0, 1) !important;
}

.resp-table-wrap .ant-form-item {
    margin-bottom: 4px;
}


/* ********************* */
.example-enter {
    transform: translateY(-100%);
    transition: .3s linear;
}
  
.example-enter-active {
    transform: translateY(0%);
}

.example-exit {
    transform: translateY(0%);
    transition: .3s ease-in-out;
}

.example-exit-active {
    transform: translateY(-100%);
}
/* ********************* */

.toptoolbar .ant-btn.ant-btn-ghost.ant-btn-lg.active {
    border: solid 1px rgb(248, 175, 0) !important;
    color: rgb(248, 175, 0);
}

.toptoolbar .ant-btn.ant-btn-ghost.ant-btn-lg.active:hover {
    border: solid 1px rgba(255, 255, 255, .9) !important;
    color: rgba(255, 255, 255, .9);
}

.resp-table-wrap .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 !important;
}

.resp-table-wrap .label-cell {
    padding: 0 0 0 40px !important;
}

.resp-table-wrap .more-wrap {
    text-align: right;
    padding: 10px;
}

/* ********************************* */

.resp-order-download-wrap {
    text-align: right;
    background-color: #FFFFFF;
    padding: 5px;
}

/* ********************************* */

.ant-collapse-borderless > .ant-collapse-item:nth-child(odd) {
    background-color: hsla(0,0%,2.4%,.1) !important;
}

.ant-collapse-borderless > .ant-collapse-item:nth-child(even) {
    background-color: transparent !important;
}