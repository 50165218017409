@media (max-width: 1024px){
    .demes-sano-reportbuilder-wrap{
        flex-direction: column;
    }
}

.demes-sano-reportbuilder-form{
    min-width: 500px;
}

.report-form {
    padding: 20px;
}

.report-form-hidden {
    visibility: hidden;
    display: none;
}

.ant-tooltip {
    z-index: 0 !important;
}

.brand-logo {
    z-index: 1000 !important;
}

.report-form-row {
    border: solid 0px #FF0000;
    margin: 0 0 -10px 0 !important;
}

.report-formitem-horizontal {
    display: inline-block;
}

.form-collapse-wrap {
    text-align: right;
    padding: 5px 20px 5px 20px;
}