.resp-table-wrap {
    display: flex;
    flex-direction: column;
    /* border: solid 10px #FF0000; */
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    /* height: 100vh; */
}

.resp-table-wrap .ant-dropdown-trigger.ant-table-filter-trigger{
    text-align: center;
}

.resp-table-wrap .ant-dropdown-trigger.ant-table-filter-trigger i {
    position: relative;
    /* top: 10px; */
}
