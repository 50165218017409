.fsframe {
    width: 100%;
    height: 100%;
    /* height: calc(100vh - 270px); */
}

.fullscreen-enabled .fsframe {
    width: 100% !important;
    height: 100% !important;
}

.ant-modal-body  .fsframe {
    width: 100% !important;
    height: calc(100vh - 80px);
}

.fsmdwrap {
    height: 100vh !important;
    padding: 0!important;
}

.fsframe-wrap {
    border: solid 0px #FF0000;
    position: relative;
    width: 100%; 
    height: calc(100vh - 132px);
    /* height: 100%; */
}

.fsframe-wrap button {
    margin: 0 20px -70px 0;
}

