.regionWrapper {
    padding: 5px;
}
.headerWrapper {
    padding-bottom: 5px;
    vertical-align: middle;
    height: 100%;
}
.centeredAssignment {
    margin-top: 5px;
}
