.globalsearchfield-wrap {
    float: left;
}

.globalsearch-tabs-wrap {
    padding: 20px 50px 20px 50px;
    background: rgba(255, 255, 255, 1) !important;
    overflow: auto !important;
}

.globalsearch-tabs-wrap .ant-tabs-tabpane.ant-tabs-tabpane-active {
    max-height: fit-content;
    overflow: auto !important;
}

.globalsearch-tabs-wrap .ant-badge-count{
    background: rgb(248, 175, 0) !important;
}

.globalsearch-tabs-wrap .ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content{
    /* overflow: auto; */
}

.globalsearch-page-wrap {
    padding: 0px;
}

.globalsearch-page-wrap hr {
    border-top: 1px solid rgb(248, 175, 0);
    /* color: rgb(248, 175, 0) !important;
    background-color: rgb(248, 175, 0) !important;
    background: rgb(248, 175, 0) !important; */
}

.globalsearch-item {
    padding: 20px;
    /* margin: 20px 0 20px 0; */
    /* border-radius: 5px; */
    cursor: pointer;
    border-bottom: solid 1px rgb(248, 175, 0);
}

.globalsearch-item:hover {
    background-color: rgba(249, 191, 51, .1);
    
}


.globalsearch-item-content-wrap {
    display: flex;
}
.globalsearch-item-icon-wrap {
    padding: 0 10px 0 0;
}

.globalsearch-item-icon-wrap i {
    font-size: 150%
}

.globalsearch-item-title {
    padding: 0 0 0px 0;
    font-size: 110%;
    font-weight: bold;
}

.globalsearch-item-content {
    font-size: 100%;
}

.globalsearch-item-link {
    font-size: 90%;
    color: rgba(249, 191, 51, 1);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 75%;
}




.globalsearch-highlighter {
    background-color: rgba(249, 191, 51, .2);
    padding: 0;
}

.globalsearch-page-teaser {
    padding: 40px 0 30px 0;
    text-align: left;
    font-size: 150%;
}

.globalsearch-page-pagination-wrap {
    text-align: center;
    padding: 20px 0 20px 0;
}