.demes-navright-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.demes-navright-toolbar {
    border: solid 0px #0000FF;
    text-align: right;
    border-bottom: solid 1px rgb(168, 168, 168);
}

.demes-navright-body {
    border: solid 0px #00FF00;
    background: #FFF;
    overflow: auto;
    height: calc(100vh - 48px);
    display: flex;
}

.demes-navright-content {
    width: 75%;
    border-right: solid 1px rgb(168, 168, 168);
    overflow: auto;
}

.demes-navright-rightnav {
    width: 25%;
    overflow: auto;
}

.demes-navright-rightnav .demes-accordion .ant-collapse-header {
    background: rgba(42, 63, 84, 1) !important;
    color: rgba(255, 255, 255, 1) !important;
}
/*
.demes-navright-rightnav .demes-accordion-2 .ant-collapse-content-box {
    height: calc(100vh - 142px + 47px);
}

.demes-navright-rightnav .demes-accordion-3 .ant-collapse-content-box {
    height: calc(100vh - 189px + 47px);
}

.demes-navright-rightnav .demes-accordion-4 .ant-collapse-content-box {
    height: calc(100vh - 283px) !important; 
}

.demes-navright-rightnav .demes-accordion-5 .ant-collapse-content-box {
    height: calc(100vh - 283px + 47px);
}

.demes-navright-rightnav .demes-accordion-6 .ant-collapse-content-box {
    height: calc(100vh - 330px + 47px);
}
*/