.demes-load-mask{
    width: calc(100vw - 80px); 
    height: calc(100vh - 67px); 
    text-align: center; 
    left: 80px;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 2;
    background-color: rgba(0,0,0,0.1);
}

.demes-load-mask.collapsed {
    width: calc(100vw - 300px); 
    left: 300px !important;
}

.demes-load-mask-spinner-wrap {
    position: relative;
    top: 300px;
}


@media (orientation: landscape) {
    .demes-load-mask-spinner-wrap-responsiv{
        position: relative;
        top: 150px;
    }
}

@media (orientation: portrait) {
    .demes-load-mask-spinner-wrap-responsiv{
        position: relative;
        top: 300px;
    }
}


.demes-load-mask-responsiv {
    width: 100vw;
    height: calc(100vh - 90px);
    text-align: center; 
    left: 0;
    right: 0;
    top: 60px;
    position: fixed;
    z-index: 10000000000;
    background-color: rgba(100,100,100,.9);
}