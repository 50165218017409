.brand-logo {
    display: block;
    text-align: center;
    padding: 20px 0 20px 0;
    margin: 0 0 10px 0;
    background-color: #FFFFFF;
    border-right: solid 1px rgba(42, 63, 84, 1); 
}

.brand-logo-collapsed{
    display: block;
    text-align: center;
    padding: 20px 0 20px 0;
    margin: 0 0 0 0;
    background-color: #FFFFFF;
}

.brand-logo-collapsed img {
    width: 50px;
    margin: 10px;
}
