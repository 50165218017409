.demes-menu-footer {
    position: absolute;
    bottom: 10px;
    text-align: center;
    width: 100%;
    color: #FFF;
    font-size: 80%;
}

.demes-menu-footer a {
    color: #FFF !important;
}

.demes-mainmenu .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0 25px !important;
}

.demes-mainmenu .ant-menu-root {
    max-height: 75vh !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.demes-mainmenu .ant-menu-inline-collapsed > .ant-menu-item{
    padding: 0 25px !important;
}

.demes-menu-outer-wrap .ant-tabs .ant-tabs-small-bar .ant-tabs-tab {
    color: #BBB !important;
}

.demes-menu-outer-wrap .ant-tabs-nav .ant-tabs-tab-active {
    color: #FFF !important;
}

.demes-menu-outer-wrap .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    background-color: transparent;
    height: 40px;
    border: solid 1px #BBB;
}

.demes-menu-outer-wrap .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    border-bottom: solid 1px rgb(42, 63, 84);
}

.demes-menu-outer-wrap .ant-tabs {
    color: #888;
}

.demes-menu-outer-wrap .ant-tabs-tab-prev, .ant-tabs-tab-next {
    color: #FFF !important;
}

.demes-menu-outer-wrap .ant-menu {
    background: transparent !important;
}
