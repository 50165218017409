/* .list-detail-wraper {
    position: relative;
    height: calc(100vh - 47px);
    height: calc(100vh - 67px);
    overflow: hidden;
    overflow-y: auto;
    padding: 20px;
    background: #fafafa;
} */

.ant-tabs-nav.ant-tabs-tab-active {
    color: #000000;
}