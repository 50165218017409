/* .list-detail-wraper {
    position: relative;
    height: calc(100vh - 47px);
    height: calc(100vh - 67px);
    overflow: hidden;
    overflow-y: auto;
    padding: 20px;
    background: #fafafa;
} */

.ant-tabs-tab-next-icon span svg{
    fill: rgb(198 198 198);
}
.ant-tabs-tab-next-icon span svg:hover{
    fill: rgb(153 153 153);
}
