/* .App {
  text-align: center;
} */

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

.App-logo {
  border: solid 2px rgb(201, 202, 204);
  /* height: 40vmin;
  pointer-events: none; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
/* 
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */

.clearfix::before,
.clearfix::after {
  content: " ";
  display: table;
}

.clearfix::after {
  clear: both;
}

.clearfix {
  *zoom: 1; /* Für den IE6 und IE7 */
}

.cookieDiv {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 5000;
  background-color: #282c34;
  opacity: 0.7;
}

.cookieDivDisabled {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0%;
  height: 0%;
  z-index: 1;
  background-color: #282c34;
  opacity: 0.35;
}

/* ****************************************************************************************************************** */

.react-cookie-banner.custom {
  position: fixed !important;
  left: 0px;
  bottom: 0px !important; 
  overflow-wrap: break-word !important;
  min-height: 100px !important;
  max-height: fit-content;
  height: fit-content;
  z-index: 1000000;
  background-color: rgb(42, 63, 84, .8) !important;
  color: rgba(255,255,255,1);
  padding: 10px;
  width: 100%;
  text-align: center;
}


.react-cookie-banner.custom a {
  display: block;
  color: rgba(255,255,255,1);
  margin: 10px 0 0 0;
  text-decoration: underline;
}

.react-cookie-banner.custom .message {
  line-height: 0px !important;
  font-weight: normal !important;
}

.react-cookie-banner.custom button {
  margin: 30px 0 20px 0 !important;
  background-color: rgba(249, 176, 0, 1) !important;
  border: solid 1px rgba(249, 176, 0, 1) !important;
  padding: 10px;
  font-weight: bold;
  
  border-radius: 5px;
  color: rgb(42, 63, 84, .8) !important;
}

/* ****************************************************************************************************************** */

.CookieDeclaration {
  display: none !important;
}

.ant-picker-dropdown{
  z-index: 1000000000000000050 !important;
}