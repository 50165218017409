.demes-dashboard-wrap{
    height: calc(100vh - 48px);
    height: calc(100vh - 87px);
    width: 100%;
    overflow: auto;
    box-sizing: border-box;
    border: solid 0px #FF0000;
    padding: 20px;
    background-image: url("/src/img/sano_back.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}

.demes-dashboard-item {
    box-sizing: border-box;
    background: #FFF;
    border: solid 1px #AAA;
    /* margin: 10px; */
}

.react-grid-layout.layout {
    width: auto;
    border: solid 0px #FF0000;
    height: calc(100vh - 47px);
    height: calc(100vh - 67px);
    padding: 10px;
    overflow: auto;
    box-sizing: border-box;
    width: 100%
}

.news-list {
    height: 320px;
    overflow: auto;
    background: rgba(255,255,255,.95);
}