.topmanuloggedin{
    border: solid 0px #FF0000;
    min-width: 400px;
    position: absolute;
    right: 10px; 
}
.dropdownHeader{
    font-weight: bold;
}

.left{
    text-align: left;
    white-space: normal;
}
.right{
    text-align: right;
    white-space: normal;
}
.center{
    text-align: center;
    white-space: normal;
}

.topbdd{
    border: solid 2px #F00000;
}