.ant-table-filter-trigger-container {
    /* padding: 12px 10px 0 10px; */
}

.ant-table-filter-trigger {
    width: auto !important;
    margin: 12px 10px 0 10px;
}

.notClickable{
    pointer-events: none;
}

.selectedRowSano{
    background-color: hsla(0,0%,2.4%,.1) ;
}