.spinner{
    position: absolute;
    top: 50%;
    left: 50%;
    font-family: arial;
    font-size: 30px;
}

.card {
    display: inline-block;
    position: relative;
    height: calc(100vh - 52px);
    width: 100%;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 1.5);
    opacity: .6;
    text-align: center;
    transform: translateY(-50%, -50%);
}
