.tablet-header-wrap {
    background-color: rgba(42, 63, 84, 1);
    background-color: rgba(255, 255, 255, 1);
    color:  rgba(255, 255, 255, 1);
    color: rgba(248, 175, 0, 1);
    border-bottom: solid 1px rgba(248, 175, 0, 1);
    height: 60px;
    display: flex;
    flex-direction: row;
}

.tablet-header-left{
    width: 140px;
    margin: 20px 10px 10px 10px;
}

.tablet-header-left img {
    width: 140px;
    
    /* padding: 10px 10px 10px 10px; */
    /* margin: 10px 10px 10px 10px; */
}
.tablet-header-center{
    flex: auto;
    text-align: center;
    padding: 15px 0 0 0;
    font-size: 120%;
    font-weight: bold;
    border: solid 0px #FF0000;
    
}
.tablet-header-right{
    min-width: 140px;
    text-align: right;
    font-size: 30px;
    padding: 5px 20px 0 0;
    color: rgba(249, 176, 0, 1);
    position: sticky;
    right: 0;
}

.tablet-header-right div {
    float: right;
}

.tablet-header-right i {
    padding: 0 0 0 20px;
}


.tablet-header-right-nolog {
    padding: 15px 15px 0 0;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    height: 60px;
    position: relative;
    top:-20%
}

.tablet-header-right-nolog button {
    background-color: rgba(249, 176, 0, 1);
    border: rgba(249, 176, 0, .5);
    font-weight: bold;
}