.ant-modal-body .about-modal {
    height: 350px;
    border: solid 0px #FF0000;
    overflow: hidden;
    /* margin: -24px; */
    /* padding: 20px; */
}

.ant-breadcrumb-link {
    cursor: pointer;
}

.about-modal .ant-card-cover img {
    width: 250px;
    padding: 40px;
    border: solid 0px #FF0000;
}