/* .sano-importstatus-wrap{
    height: calc(100vh - 47px);
    height: calc(100vh - 67px);
    padding: 20px;
    overflow: auto;
} */

.importstatus-detail-footer {
    background: rgb(240, 242, 245);
    padding: 10px;
}
.importstatus-table{
    width: auto;
}