.mainsearch-field-wrap {
    text-align: right;
    width: 100%;
    padding: 20px 20px 20px 50px;
    border: solid 0px #FF0000;
}

.mainsearch-field-wrap input {
    width: 100% !important;
    /* border: solid 3px #FF0000 !important; */
}

.mainsearch-field-wrap .globalsearchfield-wrap {
    width: 100% !important;
    /* border: solid 3px #FF0000 !important; */
}