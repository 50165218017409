.tablet-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    height: 100vh;
    border: solid 0px #FF0000;
}

.tablet-content-wrap {
    flex: auto;
    overflow: hidden;
    padding: 0;
    border: solid 0px #00FF00;
}

.tablet-content-wrap .list-detail-wrap {
    border: solid 0px #FF0000 !important;
    height: calc(100vh - 90px);
    padding: 0 !important;
}

.tablet-content-wrap .demes-dashboard-wrap {
    border: solid 0px #0000FF;
    height: calc(100vh - 60px);
}

.tablet-footer-wrap {
    background-color: rgba(42, 63, 84, 1);
    background-color: rgba(248, 175, 0, 1);
    color:  rgba(255, 255, 255, 1);
    color:  rgba(0, 0, 0, 1);
    height: 30px;
    text-align: center;
    padding: 2px;
}

.tablet-footer-wrap a {
    color: rgb(249, 176, 0);
    color: rgb(0, 0, 0);
}
