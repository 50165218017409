.ant-form-item {
    margin-bottom: 12px;
}
@media (max-width: 1024px) {
    .demes-sano-reportbuilder-wrap {
        flex-direction: column;
    }
}

.demes-sano-reportbuilder-form {
    min-width: 500px;
}

.report-form-row {
    border: solid 0px #ff0000;
    margin: 0 0 -10px 0 !important;
}

.report-formitem-horizontal {
    display: inline-block;
}
.lkvSwitch.ant-switch-small.ant-switch.ant-switch-checked {
    background-color: rgba(0, 0, 0, 0.25);
}
.lkvSwitch .ant-click-animating-node{
    animation-duration: 0s !important;
}