.company-select-wrap {
    z-index: 100000000000000;
}

.ant-dropdown-placement-bottomRight{
    z-index: 1000000000000000001 !important;
} 

.company-select-wrap {
    display: flex;

}

.company-select-button {
    display: inline-block !important;
}

.company-select-value {
    display: inline-block !important;
    padding: 0 0 0 10px;
}