/* width */
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}
	
/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1; 
	border-radius: 5px;
}
	 
/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(248, 175, 0, .7);
	border-radius: 5px;
}
	
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgba(248, 175, 0, 1);
	position: drag;
	border-radius: 5px;
}