.tablet-header-nav-wrap {
    z-index: 100000;
    position: absolute;
    color: rgba(255,255,255,1);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 90%;
    max-height: calc(100vh - 130px) !important;
    margin: auto;
    border-radius: 10px;
    overflow: auto;
    border: solid 1px rgba(42, 63, 84, 1);
    background-color:  rgba(255,255,255,1);;
}
/*
.tablet-header-nav-wrap .ant-tabs-content {
    max-height: calc(100vh - 200px) !important;
    overflow-y: auto !important;
    overflow-x: hidden;
}
*/
.tablet-nav-item {
    color: rgba(42, 63, 84, 1);
    padding: 8px;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    background-color: rgba(255,255,255,.95);
    border-bottom: solid 1px rgba(42, 63, 84, 1);
}

.tablet-nav-item:hover{
    background-color: rgba(42, 63, 84, .9) !important;
    color: rgb(206, 152, 17);
}

.tablet-nav-item:last-child{
    border-bottom: none !important;
}

.tablet-nav-parent {
    border: solid 0px #FF0000;
}
.tablet-nav-icon-cell {
    max-width: 60px;
    text-align: center;
}

.tablet-nav-icon-cell i {
    padding: 0 5px 0 5px;
}

.tablet-nav-title-cell {
    flex: auto;
}
.tablet-nav-expand-cell {
    text-align: right;
    width: 30px;
    font-size: 20px;
    padding: 0 0 0 0;
}

.tablet-nav-endpoint{
    border: solid 0px #00FF00;
}

.tablet-nav-back-wrap {
    text-align: right;
    font-size: 20px;
    color: rgba(42, 63, 84, 1);
    border-bottom: solid 2px rgba(42, 63, 84, 1);
    padding: 5px 20px 5px 0;
    background-color: rgba(255,255,255,1);
    color: rgba(255,255,255,1);
    background-color: rgba(42, 63, 84, .9);
}

.tablet-nav-dim {
    position: absolute;
    width: 100%;
    height: calc(100vh - 90px);
    background-color: rgba(42, 63, 84, .8);
    top: 60px;
    left: 0;
    bottom: 0;
    right: 0;
    border: solid 0px #FF0000;
    z-index: 10000;  
}

.tablet-header-logout-wrap {
    text-align: center;
    display: block;
    padding: 20px;
    border-top: solid 2px rgba(206, 152, 17, 1);
}

.tablet-header-logout-wrap button {
    background-color: rgba(206, 152, 17, 1);
    border-radius: 5px;
    border: solid 1px rgba(206, 152, 17, 1);
    color: rgba(255, 255, 255, 1);
}

.close-wrap {
    text-align: right;
    color: #000000;
    padding: 5px 18px 5px 0;
    font-size: 150%;
}

.close-wrap:hover {
    cursor: pointer;
}