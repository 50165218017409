.demes-split-wrap-nested {
    border: solid 0px #FF0000;
    height: calc(100vh - 80px);
    width: 100%;
    overflow: hidden;
}

.demes-split-wrap-nested .splitter-layout {
    border: solid 0px #00FF00;
    height: calc(100vh - 80px);
    
}