.ant-collapse-content-box {
    overflow: auto;
}

.demes-accordion .ant-collapse-header {
    background: rgba(42, 63, 84, 1) !important;
    color: rgba(255, 255, 255, 1) !important;
}


.demes-accordion-2-won .ant-collapse-content-box {
    height: calc(100vh - 142px);
}

.demes-accordion-3-won .ant-collapse-content-box {
    height: calc(100vh - 189px);
}

.demes-accordion-4-won .ant-collapse-content-box {
    height: calc(100vh - 236px);
}

.demes-accordion-5-won .ant-collapse-content-box {
    height: calc(100vh - 283px);
}

.demes-accordion-6-won .ant-collapse-content-box {
    height: calc(100vh - 330px);
}


.demes-accordion-2-wn .ant-collapse-content-box {
    height: calc(100vh - 142px - 48px);
}

.demes-accordion-3-wn .ant-collapse-content-box {
    height: calc(100vh - 189px - 48px);
}

.demes-accordion-4-wn .ant-collapse-content-box {
    height: calc(100vh - 236px - 48px);
}

.demes-accordion-5-wn .ant-collapse-content-box {
    height: calc(100vh - 283px - 48px);
}

.demes-accordion-6-wn .ant-collapse-content-box {
    height: calc(100vh - 330px - 48px);
}

/* ****************************************************************** */

.demes-accordion-2 .ant-collapse-content-box {
    height: calc(100vh - 142px);
}

.demes-accordion-3 .ant-collapse-content-box {
    height: calc(100vh - 189px);
}

.demes-accordion-4 .ant-collapse-content-box {
    height: calc(100vh - 236px);
}

.demes-accordion-5 .ant-collapse-content-box {
    height: calc(100vh - 283px);
}

.demes-accordion-6 .ant-collapse-content-box {
    height: calc(100vh - 330px);
}
