.export-wrapper {
    padding: 20px;
}

.export-item {
    text-align: left !important;
    padding: 10px;
    background-color: rgba(250, 250, 250, 1);
    display: flex;
    flex-direction: column;
    width: 100%;
}
