.load-mask-frame {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.1);
    text-align: center; 
}

.load-mask-frame .ant-spin {
    position: relative;
    top: 200px
}